import React from "react";

import { contact, backgroundGraphic, foregroundGraphic } from "./ContactSection.module.scss";

import Button from "../components/Button";

import { combine, getClassNameModifiers } from "../utils/classNames";

const ContactSection = props => {
  const {
    className,
    heading,
    body,
    buttonLabel,
    buttonColor,
    backgroundImg,
    foregroundImg,
    foregroundImgX,
    foregroundImgY,
    foregroundImgWidth,
    height,
    href,
  } = props;

  const modifiers = getClassNameModifiers(props, ["backgroundColor", "headingColor"]);

  return (
    <section className={combine(contact, modifiers, className)} style={{ height: height + "rem" }}>
      <h2>{heading}</h2>
      <p>{body}</p>
      <Button color={buttonColor && buttonColor} href={href}>{buttonLabel}</Button>
      {backgroundImg && <img src={backgroundImg} className={backgroundGraphic} alt="" />}
      {foregroundImg && (
        <img
          src={foregroundImg}
          alt=""
          className={foregroundGraphic}
          style={{
            bottom: foregroundImgY + "rem",
            right: foregroundImgX + "rem",
            width: foregroundImgWidth + "rem",
          }}
        />
      )}
    </section>
  );
};

export default ContactSection;
