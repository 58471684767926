import React from "react";

import { network, logoCarousel, copy, buttonContainer } from "./NetworkSection.module.scss";

import Button from "../components/Button";
import LogoCarousel from "../partials/LogoCarousel";
import CTAButtons from "../partials/CTAButtons";

const NetworkSection = props => {
  const { heading, logos, height, ctaButtons, body, headingWidth } = props;

  return (
    <section className={network}>
      <h2 style={{ width: headingWidth ? headingWidth + "rem" : null }}>{heading}</h2>
      <LogoCarousel className={logoCarousel} logos={logos} height={height} />
      {body && <h4 className={copy}>{body}</h4>}
      <CTAButtons
        className={buttonContainer}
        buttons={ctaButtons}
        style={{
          marginTop: body ? "1.875rem" : null,
        }}
      />
    </section>
  );
};

export default NetworkSection;
