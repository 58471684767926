import React from "react";

import { base } from "./Textbox.module.scss";

import { combine, getClassNameModifiers } from "../utils/classNames";

const Textbox = props => {
  const { heading, body, className } = props;

  const modifiers = getClassNameModifiers(props, ["color"]);

  return (
    <div className={combine(base, modifiers, className)}>
      <h2>{heading}</h2>
      <p>{body}</p>
    </div>
  );
};

export default Textbox;
