import React from "react";

import { info, item } from "./InfoSection.module.scss";

import Textbox from "./Textbox";

const InfoSection = props => {
  const {
    leftHeading,
    leftBody,
    leftColor,
    middleHeading,
    middleBody,
    middleColor,
    rightHeading,
    rightBody,
    rightColor,
    style,
  } = props;

  return (
    <section className={info} style={style}>
      <Textbox className={item} heading={leftHeading} body={leftBody} color={leftColor} />
      <Textbox className={item} heading={middleHeading} body={middleBody} color={middleColor} />
      <Textbox className={item} heading={rightHeading} body={rightBody} color={rightColor} />
    </section>
  );
};

export default InfoSection;
