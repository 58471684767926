import React from "react";

import { caseStudiesContainer } from "./CaseStudiesSection.module.scss";

import Carousel from "../components/Carousel";
import CaseStudy from "../partials/CaseStudy";

const CaseStudiesSection = props => {
  const { caseStudies, color } = props;

  if (!caseStudies || caseStudies.length == 0) {
    return null;
  }

  return (
    <section className={caseStudiesContainer}>
      <Carousel>
        {caseStudies.map((caseStudy, index) => (
          <CaseStudy
            key={index}
            color={color}
            title={caseStudy.featured.headline}
            body={caseStudy.featured.extract}
            author={caseStudy.featured.author}
            authorDescription={caseStudy.featured.authorRole}
            url={caseStudy.slug}
            imageSrc={caseStudy.featureImages[0].src}
            imageAlt={caseStudy.featureImages[0].alt}
            noButton={caseStudies.length === 1}
          />
        ))}
      </Carousel>
    </section>
  );
};

export default CaseStudiesSection;
