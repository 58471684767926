import React from "react";

import { category, label } from "./Category.module.scss";

const Category = props => {
  const { title, iconSrc, imageSrc } = props;

  return (
    <div className={category}>
      <img src={imageSrc} alt="" />
      <div className={label}>
        <img src={iconSrc} alt="" />
        <h2>{title}</h2>
      </div>
    </div>
  );
};

export default Category;
