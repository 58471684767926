import React from "react";

import {
  banner,
  buttonContainer,
  backgroundImage,
  foregroundImage,
} from "./BannerSection.module.scss";

import CTAButtons from "../partials/CTAButtons";

import { combine, getClassNameModifiers } from "../utils/classNames";

const BannerSection = props => {
  const {
    theme,
    heading,
    body,
    height,
    marginBottom,
    backgroundImg,
    backgroundImgY,
    backgroundImgX,
    backgroundImgWidth,
    backgroundImgFlipX,
    foregroundImg,
    foregroundImgY,
    foregroundImgX,
    foregroundImgWidth,
    foregroundImgFlipX,
    ctaButtons,
  } = props;

  const modifiers = getClassNameModifiers(props, ["theme"]);

  const buttonColor = theme === "cerise" ? "navy" : "cerise";

  return (
    <section
      className={combine(banner, modifiers)}
      style={{ height: height + "rem", marginBottom: marginBottom + "rem" }}
    >
      <div>
        <h1>{heading}</h1>
        <p>{body}</p>
        <CTAButtons className={buttonContainer} buttons={ctaButtons} />
      </div>
      <img
        className={backgroundImage}
        src={backgroundImg}
        alt=""
        style={{
          bottom: backgroundImgY + "rem",
          right: !backgroundImgFlipX && backgroundImgX + "rem",
          left: backgroundImgFlipX && backgroundImgX + "rem",
          width: backgroundImgWidth + "rem",
        }}
      />
      {foregroundImg && (
        <img
          className={foregroundImage}
          src={foregroundImg}
          alt=""
          style={{
            bottom: foregroundImgY + "rem",
            right: !foregroundImgFlipX && foregroundImgX + "rem",
            left: foregroundImgFlipX && foregroundImgX + "rem",
            width: foregroundImgWidth + "rem",
          }}
        />
      )}
    </section>
  );
};

export default BannerSection;
