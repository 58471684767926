import React, { useState } from "react";

import { base, button, container } from "./Carousel.module.scss";

const Carousel = props => {
  const { children } = props;

  const [index, setIndex] = useState(0);

  const hasMultiple = Array.isArray(children) && children.length > 1;

  const renderContent = () => {
    if (hasMultiple) {
      return children.map((child, index) => <div key={index}>{child}</div>);
    } else {
      return <div>{children}</div>;
    }
  };

  const handleClick = () => {
    setIndex((index + 1) % children.length);
  };

  return (
    <div className={base}>
      {hasMultiple && (
        <img
          src="/images/carousel-arrow.svg"
          alt="Arrow left"
          className={button}
          onClick={handleClick}
        />
      )}
      <div className={container} style={{ transform: `translateX(-${index}00%` }}>
        {renderContent()}
      </div>
    </div>
  );
};

export default Carousel;
