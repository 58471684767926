import React from "react";

import { categories, categoriesContainer } from "./CategoriesSection.module.scss";

import Category from "../partials/Category";

const CategoriesSection = props => {
  const { copy, copyWidth, title1, title2, title3 } = props;

  return (
    <section className={categories}>
      <h3
        style={{
          width: copyWidth ? copyWidth + "rem" : null,
        }}
      >
        {copy}
      </h3>
      <div className={categoriesContainer}>
        <Category
          title={title1 || "On premise"}
          iconSrc="/images/onsite.svg"
          imageSrc="/images/onsite-image@3x.png"
        />
        <Category
          title={title2 || "In home"}
          iconSrc="/images/home.svg"
          imageSrc="/images/at-home-image@3x.png"
        />
        <Category
          title={title3 || "Online"}
          iconSrc="/images/online.svg"
          imageSrc="/images/online-image@3x.png"
        />
      </div>
    </section>
  );
};

export default CategoriesSection;
