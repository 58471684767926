import React from "react";

import "../../styles/global.scss";

import Layout from "../layouts/Layout";
import BannerSection from "../partials/BannerSection";
import CategoriesSection from "../partials/CategoriesSection";
import NetworkSection from "../partials/NetworkSection";
import CaseStudiesSection from "../partials/CaseStudiesSection";
import PlatformSection from "../partials/PlatformSection";
import FeedbackSection from "../partials/FeedbackSection";
import ContactSection from "../partials/ContactSection";
import InfoSection from "../partials/InfoSection";
import { partnerLogos, agencyLogos } from "../pages/index";

import { infoHeading } from "./agencies.module.scss";

import { useMediaQuery } from "../utils/hooks";

const AgenciesPage = props => {
  const {
    pageContext: { caseStudies },
  } = props;

  const isMobile = useMediaQuery("(max-width: 575px)");

  const ctaButtons = [
    { label: "Start today", href: "/book-a-demo" },
    { label: "Schedule a demo", href: "/book-a-demo" },
  ];

  return (
    <Layout
      pageTitle="Partners | Brandcrush"
      ogImage="/images/seo/partner.jpg"
      ogDescription="End-to-end software powering businesses around the world to monetize and maximize their owned media assets through retail media, activations and commercial partnerships."
    >
      <BannerSection
        theme="teal"
        heading="Scale high-engagement campaigns in minutes"
        body="Seamlessly book shopper marketing, activations and partnerships through a network of owned media channels"
        height={isMobile ? 25.0 : 30.25}
        marginBottom={isMobile ? 13.938 : 10.0}
        backgroundImg="/images/cloud-partner.svg"
        backgroundImgX={isMobile ? -4.0 : -1.7}
        backgroundImgY={isMobile ? -7.77 : -2.0}
        backgroundImgWidth={isMobile ? 20.563 : 48.138}
        backgroundImgFlipX={isMobile}
        foregroundImg="/images/girl-check-mark@3x.png"
        foregroundImgX={isMobile ? -0.6 : 5.625}
        foregroundImgY={isMobile ? -6.548 : 0.0}
        foregroundImgWidth={isMobile ? 19.5 : 36.813}
        foregroundImgFlipX={isMobile}
        ctaButtons={[
          { ...ctaButtons[0], color: "navy" },
          { ...ctaButtons[1], color: "white" },
        ]}
      />
      <CategoriesSection
        copy="Activate and reach millions of targeted consumers"
        copyWidth={48.25}
      />
      <NetworkSection
        heading="Get cut-through with a warm introduction from these Media Owners and hundreds more"
        body="ASK OUR TEAM HOW YOU CAN BECOME A PREFERRED AGENCY PARTNER"
        logos={partnerLogos(isMobile)}
        height="5.0"
        autoplay
        ctaButtons={ctaButtons}
      />

      <FeedbackSection
        imageSrc="/images/mindshare@3x.png"
        feedback="We believe in the power of technology to transform our clients' businesses, Brandcrush offers a fantastic way to plan, book and manage hyper-targeted campaigns through one simple dashboard."
        fullName="Angie Alexis"
        jobTitle="Associate Investment Director, Mindshare"
      />

      <h3 className={infoHeading}>Maximize data driven, planning, buying & reporting</h3>

      <InfoSection
        style={{
          marginTop: isMobile ? "2.5rem" : "4.375rem",
        }}
        leftHeading="SMART ACTIVATIONS"
        leftBody="Discover untapped activation opportunities targeting life style audiences from foodies and adventure seekers to fitness enthusiasts, travellers and more"
        leftColor="light-blue"
        middleHeading="Life Stage Targeting"
        middleBody="Tap into a powerful network offering connections with consumers at different life stages; boomers, parents, moms to be, millennials, teens"
        middleColor="teal"
        rightHeading="CONTEXTUAL MARKETING"
        rightBody="Reach targeted audiences at the right place and time; retailer boxes,  food deliveries, rideshares,  gyms, offices, universities, hotels and more"
        rightColor="blue"
      />

      <PlatformSection
        heading={null}
        topHeading="Discover"
        topCopy="Thousands of activation spaces via a network of influential businesses"
        topGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/discover-rideshare@3x.png",
            x: isMobile ? 1.988 : 4.375,
            y: isMobile ? 10.37 : 22.813,
            delay: 0.1,
            width: isMobile ? 5.995 : 13.188,
          },
          {
            image: "/images/discover-recreation@3x.png",
            x: isMobile ? 10.767 : 23.688,
            y: isMobile ? 10.37 : 22.813,
            delay: 0.2,
            width: isMobile ? 5.995 : 13.188,
          },
          {
            image: "/images/discover-d2c@3x.png",
            x: isMobile ? 1.988 : 4.375,
            y: isMobile ? 8.04 : 17.688,
            delay: 0.3,
            width: isMobile ? 5.995 : 13.188,
          },
          {
            image: "/images/discover-retail@3x.png",
            x: isMobile ? 10.767 : 23.688,
            y: isMobile ? 8.04 : 17.688,
            delay: 0.4,
            width: isMobile ? 5.995 : 13.188,
          },
          {
            image: "/images/discover-health@3x.png",
            x: isMobile ? 1.988 : 4.375,
            y: isMobile ? 5.71 : 12.563,
            delay: 0.5,
            width: isMobile ? 5.995 : 13.188,
          },
          {
            image: "/images/discover-hospitality@3x.png",
            x: isMobile ? 10.767 : 23.688,
            y: isMobile ? 5.71 : 12.563,
            delay: 0.6,
            width: isMobile ? 5.995 : 13.188,
          },
          {
            image: "/images/discover-corporate@3x.png",
            x: isMobile ? 1.988 : 4.375,
            y: isMobile ? 3.381 : 7.438,
            delay: 0.7,
            width: isMobile ? 5.995 : 13.188,
          },
          {
            image: "/images/discover-shopping@3x.png",
            x: isMobile ? 10.767 : 23.688,
            y: isMobile ? 3.381 : 7.438,
            delay: 0.8,
            width: isMobile ? 5.995 : 13.188,
          },
        ]}
        middleHeading="Create"
        middleCopy="Hyper-targeted and scalable campaigns in minutes"
        middleGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/create-checklist@3x.png",
            x: isMobile ? 1.128 : 2.482,
            y: isMobile ? 6.449 : 14.188,
            delay: 0.2,
            height: isMobile ? 5.114 : 11.25,
          },
          {
            image: "/images/create-image@3x.png",
            x: isMobile ? 7.997 : 17.594,
            y: isMobile ? 5.568 : 12.25,
            delay: 0.3,
            height: isMobile ? 6.563 : 14.438,
          },
          {
            image: "/images/create-copy@3x.png",
            x: isMobile ? 7.997 : 17.594,
            y: isMobile ? 2.443 : 5.375,
            delay: 0.4,
            height: isMobile ? 2.557 : 5.625,
          },
        ]}
        bottomHeading="Activate"
        bottomCopy="Request to activate and hear a yay or nay from partners, or accept a proposal. Our team and workflows will guide you through the rest."
        bottomGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/activate-top-left@3x.png",
            x: isMobile ? 0.895 : 1.969,
            y: isMobile ? 7.851 : 17.272,
            delay: 0.1,
            height: isMobile ? 3.41 : 7.5,
          },
          {
            image: "/images/activate-top-right@3x.png",
            x: isMobile ? 9.787 : 21.531,
            y: isMobile ? 7.851 : 17.272,
            delay: 0.2,
            height: isMobile ? 3.41 : 7.5,
          },
          {
            image: "/images/activate-bottom-left@3x.png",
            x: isMobile ? 0.895 : 1.969,
            y: isMobile ? 2.737 : 6.022,
            delay: 0.3,
            height: isMobile ? 3.41 : 7.5,
          },
          {
            image: "/images/activate-bottom-right@3x.png",
            x: isMobile ? 9.787 : 21.531,
            y: isMobile ? 2.737 : 6.022,
            delay: 0.4,
            height: isMobile ? 3.41 : 7.5,
          },
        ]}
        ctaButtons={ctaButtons}
        theme="teal"
      />

      <CaseStudiesSection caseStudies={caseStudies} />

      <InfoSection
        leftHeading="SCALABLE"
        leftBody="Book with one partner, or scale national campaigns with hundreds"
        leftColor="light-pink"
        middleHeading="TRANSPARENT PRICING"
        middleBody="No sign up fees - all rates are determined by our partners"
        middleColor="purple"
        rightHeading="REAL TIME AVAILABILITY"
        rightBody="Have access to real time availability on our marketplace or private media portals to avoid delays"
        rightColor="cerise"
      />

      <NetworkSection
        heading="Trusted by the world's leading brands and agencies"
        headingWidth={isMobile ? null : 40}
        logos={agencyLogos(isMobile)}
        height="6.25"
        ctaButtons={[{ ...ctaButtons[0], color: "navy" }, { ...ctaButtons[1] }]}
      />

      <ContactSection
        heading="Need help?"
        body="We've got you; our team offers full concierge support"
        buttonLabel="Get in touch"
        backgroundColor="grey"
        buttonColor="navy"
        foregroundImg="/images/brand-contact.svg"
        foregroundImgX={4.438}
        foregroundImgY={isMobile ? -5.625 : -3.75}
        foregroundImgWidth={isMobile ? 18.875 : 37.938}
        height={24.375}
        href="/contact"
      />
    </Layout>
  );
};

export default AgenciesPage;
