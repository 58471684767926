import React from "react";
import { combine } from "../utils/classNames";

import { base, feedbackContainer, image, content } from "./FeedbackSection.module.scss";

const FeedbackSection = props => {
  const { className, imageSrc, imageAlt, feedback, fullName, jobTitle } = props;

  return (
    <div className={combine(base, className)}>
      <div className={feedbackContainer}>
        <div className={image}>
          <img src={imageSrc} alt={imageAlt} />
        </div>
        <div className={content}>
          <img src="/images/quote-white.svg" alt="quote-white" />
          <p>{feedback}</p>
          <h4>{fullName}</h4>
          <h5>{jobTitle}</h5>
        </div>
      </div>
    </div>
  );
};

export default FeedbackSection;
